import React from 'react';
import Label from '../Label/Label';
import { useTheme } from '../../contexts/ThemeContext';
import { FullStackArt } from '../../assets/FullStackArt';
import './Lessons.scss';

export function Lessons() {
    const { backgroundColor, mainTextColor, paragraphTextColor } = useTheme();

    return (
        <div className="lessons-container" style={{backgroundColor: backgroundColor}}>
            <div className="lessons-content">
                <Label title="Learn"/>   
                <h1 style={{color: mainTextColor}}>Coming soon!</h1>
                <p style={{color: paragraphTextColor}}>
                    I have not had the time to begin seriously recording lessons,
                    but I intend to release a full introduction to FullStack Development 
                    for complete beginners in early 2022. Feel free to contact me with video
                    suggestions. In the meantime, feel free to check out my blog :)
                </p>  
                <FullStackArt />
            </div>      
        </div>
    )
}

import React from 'react';
import LabelFlair from './LabelFlair';
import Theme from '../Theme';
import { useTheme } from '../../contexts/ThemeContext';
import './label.scss'

const Label = (props) =>  {
    const { isDarkTheme, mainTextColor, labelColor } = useTheme();
    const textColorTwo = isDarkTheme ? Theme.textColorTwoDark : Theme.textColorTwo;

    if(props.isTitle){
        return(
            <div 
                style={
                    {
                        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 30%, ${labelColor} 30%, ${labelColor})`
                    }
                } 
                className="label-header-parent-title"
                >
                <LabelFlair className="flair-before" color={mainTextColor} />
                    <h2 className="label-header" style={{color: textColorTwo}}>{props.title}</h2>
                <LabelFlair className="flair-after" color={mainTextColor} />
            </div>
        )
    } else if(props.alternate){
        return(
            <div 
            className="label-header-parent"
            style={
                {
                    backgroundColor: "rgb(154, 77, 255)"
                }
            }>
            <LabelFlair className="flair-before" color={mainTextColor} />
            <h2 className="label-text" style={{color:'white'}}>{props.title}</h2>
        </div>
        )
    } else {
        return(
            <div 
                className="label-header-parent"
                style={
                    {
                        backgroundColor: labelColor
                    }
                }>
                <LabelFlair className="flair-before" color={mainTextColor} />
                <h2 className="label-text" style={{color: textColorTwo}}>{props.title}</h2>
            </div>
        )
    }
}

export default Label;
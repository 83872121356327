import React from 'react'
import { Link } from 'gatsby'
import { Landscape } from './Landscape';
import './Footer.scss';

export function Footer() {
    return (
        <div className="footer">
            <Landscape className="landscape" />
            <p className="info-text">Created on the 🍇 JAMStack 💨 with joy :)</p>
            <div className="links-container">
                <div className="home-container">
                    <Link className="link link" to="/">Return Home</Link>
                </div>
                <div className="pp-container">
                    <Link className="link" to="/privacy">View Privacy Policy</Link>
                </div>
            </div>
        </div>
    )
}

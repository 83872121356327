import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import { Link } from 'gatsby';
import { useTheme } from '../../contexts/ThemeContext';
import { useSetTheme } from '../../contexts/ThemeContext';
import { BsSun, BsMoon } from 'react-icons/bs';
import { CgDarkMode } from 'react-icons/cg';
import { AiOutlineMenu } from 'react-icons/ai';

function getWindowWidth() {
    const isBrowser = typeof window !== "undefined";
    console.log('isBrowser', isBrowser)
    if (isBrowser){
        const { innerWidth: width } = window;
        return width;
    }
}

const NavbarTwo = () => {
    const [hasMounted, setHasmounted] = useState(false);
    const [viewWidth, setViewWidth] = useState(getWindowWidth());
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    console.log('viewWidth', viewWidth);
    console.log('isMenuOpen', isMenuOpen);

    useEffect(() => {
        setHasmounted(true);
    }, [])

    useEffect(() => {
        function handleResize() {
          setViewWidth(getWindowWidth());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const { isDarkTheme } = useTheme();
    const { toggleTheme } = useSetTheme();

    if(hasMounted === false){
        return null
    }
    
    if(viewWidth > 700){
        return(
            <nav className="navbar">
                <ul className="nav-list">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/computer-science">Comp Sci</Link></li>
                    <li><Link to="/learn">Learn</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    {/* { isDarkMode ? 
                        <BsSun color="white" onClick={toggleTheme} size={30} /> 
                        : 
                        <BsMoon color="white" size={30} onClick={toggleTheme} />
                    } */}
                    <CgDarkMode 
                        size={30}
                        onClick={toggleTheme}
                        className={ isDarkTheme ? "mode-toggle mode-dark" : "mode-toggle mode-light"}
                    />
                </ul>
            </nav>
        )
    } else {
        return (
            <nav className="navbar-mobile">
                <CgDarkMode 
                    size={30}
                    onClick={toggleTheme}
                    color={isDarkTheme ? 'grey' : 'white' }
                    className="mode-toggle"
                />
                <AiOutlineMenu 
                    className="menu-icon" 
                    color="white" 
                    size={40} 
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
                <ul 
                    className={isMenuOpen ? "nav-list" : "nav-list-hidden"}
                >
                    <li><Link href="/">Home</Link></li>
                    <li><Link href="/computer-science">Comp Sci</Link></li>
                    <li><Link href="/learn">Learn</Link></li>
                    <li><Link href="/blog">Blog</Link></li>
                    <li><Link href="/contact">Contact</Link></li>
                    {/* { isDarkMode ? 
                        <BsSun color="white" onClick={toggleTheme} size={30} /> 
                        : 
                        <BsMoon color="white" size={30} onClick={toggleTheme} />
                    } */}
                </ul>
            </nav>
        )
    }
}

export default NavbarTwo;
import React from 'react';

const LabelFlair = ({color, className}) => {
    return(
        <svg className={className} id="Layer_1" viewBox="0 0 58.51 58.39" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
            <defs/>
            <rect fill={color} height="18.35" width="2" x="48.84" y="0.5"/>
            <rect fill={color} height="18.35" width="2" transform="translate(93.32 -73.91) rotate(90)" x="83.21" y="35.31"/>
            <rect fill={color} height="11.61" width="2" transform="translate(70.37 -50.95) rotate(90)" x="60.25" y="38.68"/>
            <rect fill={color} height="11.61" width="2" transform="translate(50.79 -31.37) rotate(90)" x="40.67" y="38.68"/>
            <rect fill={color} height="11.61" width="2" transform="translate(132.99 99.94) rotate(180)" x="83.49" y="61.57"/>
            <rect fill={color} height="11.61" width="2" transform="translate(132.99 138.97) rotate(180)" x="83.49" y="81.08"/>
        </svg>
    )
}

export default LabelFlair;
import React from 'react'
import NavbarTwo from '../components/Navbar/NavbarTwo';
import { Lessons } from '../components/Lessons/Lessons';
import { Footer } from '../components/Footer/Footer';


export default function Learn() {
    return (
        <>
            <NavbarTwo />
            <Lessons />
            <Footer />
        </>
    )
}

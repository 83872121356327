import React from 'react';

export function Landscape({className, colorOne, colorTwo, colorThree}) {
    return (
        <svg 
            className={className} 
            id="Layer_1" 
            viewBox="0 0 1080 251.9" 
            xmlns="http://www.w3.org/2000/svg" 
            data-name="Layer 1"

        >
            <defs/>
            <circle 
                
                cx="56.86" 
                cy="68.52" 
                r="4.73" 
                fill={colorOne || '#4D267F'}
            />
            <circle 
                className="cls-1" 
                cx="37.04" 
                cy="51.42" 
                r="3.25" 
                fill={colorOne || '#4D267F'}
            />
            <path 
                
                d="M67.68,99.83A28.31,28.31,0,0,0,16.13,78.3,23.75,23.75,0,0,1,34,120.93,28.31,28.31,0,0,0,67.68,99.83Z" 
                transform="translate(0 -24.87)" 
                fill={colorOne || '#4D267F'}
            />
            <path 
                
                d="M0,275.35c131.53-20.47,327.85-42.79,568.08-40.62,213.1,1.93,388.59,22.48,511.92,42" 
                transform="translate(0 -24.87)" 
                fill={colorOne || '#4D267F'}
            />
            <polygon 
                
                points="74.15 127.06 77.67 125.2 77 129.13 79.85 131.91 75.91 132.48 74.15 136.05 72.39 132.48 68.44 131.91 71.3 129.13 70.62 125.2 74.15 127.06" 
                fill={colorTwo || '#9A4DFF'} 
            />
            <polygon 
                
                points="116.47 32.97 119.99 31.12 119.32 35.04 122.17 37.82 118.23 38.39 116.47 41.96 114.71 38.39 110.77 37.82 113.62 35.04 112.94 31.12 116.47 32.97" 
                fill={colorTwo || '#9A4DFF'}
            />
            <polygon 
                
                points="137.78 90.95 145.69 91.88 140.14 97.59 141.7 105.4 134.56 101.89 127.61 105.78 128.74 97.9 122.89 92.5 130.74 91.14 134.06 83.91 137.78 90.95" 
                fill={colorThree || '#9FF9D4'}
            />
            <polygon 
                
                points="189.13 7.04 197.04 7.97 191.49 13.68 193.05 21.49 185.91 17.98 178.96 21.88 180.09 13.99 174.24 8.59 182.09 7.23 185.41 0 189.13 7.04" 
                fill={colorThree || '#9FF9D4'}
            />
        </svg>
    )
}
